import React, { useState } from 'react';
import classNames from 'classnames';

import { propTypes } from '../../../util/types';
import { Avatar, ListingCard, UserCard, NamedLink, Button, PaginationLinks, IconVerified, Modal, FieldTextInput } from '../../../components';

import css from './SearchResultsPanel.module.css';

import { FaInstagram, FaYoutube, FaFacebook, FaWhatsapp, FaTwitter, FaMapMarkerAlt, FaCheckCircle, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const formatFollowers = (count) => {
  if (count >= 1000000) {
    return (count / 1000000).toFixed(1) + 'M';
  } else if (count >= 1000) {
    return (count / 1000).toFixed(1) + 'K';
  }
  return count;
};

const ProfileSection = (props) => {
  const { currentUser, user, listingCategories, onUpdateCurrentUser, updateCurrentUserInProgress, creatorNiche, setOpenWishlistSelector } = props;

  // user is on a wishlists list from currentUser
  const isOnWishlist = currentUser?.attributes.profile.publicData.wishlists?.some(wishlist => wishlist.users.includes(user.id.uuid));
  
  return (
    <div className={css.container}>
      <div className={css.profileContainer}>
        <div className={css.header}>
          <Avatar className={css.profileImage} user={user} />
          <div className={css.nameLocation}>
            <p className={css.name}>
              {user?.attributes?.profile?.displayName}{user?.attributes?.profile?.publicData.user_verified && <IconVerified className={css.verifiedIcon} />}
              {user?.attributes?.profile?.publicData?.verified_professionalCreator && <span className={css.professionalCreator}><IconVerified className={css.whiteVerifiedIcon} /> Pro</span>}
            </p>
            {user?.attributes?.profile?.publicData?.location?.selectedPlace?.address && (
              <div className={css.location}>
                <FaMapMarkerAlt className={css.locationIcon} />
                {user?.attributes?.profile?.publicData?.location?.selectedPlace?.address}
                {creatorNiche && ' | ' + creatorNiche}
              </div>
            )}
          </div>
        </div>
        <div className={css.socialMedia}>
          {user && user?.attributes?.profile?.publicData?.user_instagram_url && (
            <div className={css.socialItem}>
              <FaInstagram className={css.instagramIcon} />
              {user?.attributes.profile.publicData.user_instagram_followers && (
                <span>
                  {formatFollowers(user?.attributes.profile.publicData.user_instagram_followers)}
                </span>
              )}
              {user?.attributes?.profile?.publicData.instagram_blue_badge?.split('_')?.includes('yes') && (
                <IconVerified className={css.socialVerified} />
              )}
            </div>
          )}
          {user && user?.attributes?.profile?.publicData?.user_youtube_url && (
            <div className={css.socialItem}>
              <FaYoutube className={css.youtubeIcon} />
              {user?.attributes.profile.publicData.user_youtube_subscribers && (
                <span>
                  {formatFollowers(user?.attributes.profile.publicData.user_youtube_subscribers)}
                </span>
              )}
              {!user?.attributes?.profile?.publicData.youtube_play_button && (
                <IconVerified className={css.greenVerified} />
              )}
            </div>
          )}
          {user && user?.attributes?.profile?.publicData?.user_x_handle && (
            <div className={css.socialItem}>
              <FaXTwitter className={css.twitterIcon} />
              {user?.attributes.profile.publicData.user_x_followers && (
                <span>
                  {formatFollowers(user?.attributes.profile.publicData.user_x_followers)}
                </span>
              )}
              {user?.attributes?.profile?.publicData.x_verified?.split('_')?.includes('yes') && (
                <IconVerified className={css.socialVerified} />
              )}
            </div>
          )}
          {user && user?.attributes?.profile?.publicData?.user_facebook_url && (
            <div className={css.socialItem}>
              <FaFacebook className={css.facebookIcon} />
              {user?.attributes.profile.publicData.user_facebook_followers && (
                <span>
                  {formatFollowers(user?.attributes.profile.publicData.user_facebook_followers)}
                </span>
              )}
              {user?.attributes?.profile?.publicData.facebook_verified?.split('_')?.includes('yes') && (
                <IconVerified className={css.socialVerified} />
              )}
            </div>
          )}
          {user && user?.attributes?.profile?.publicData?.user_whatsapchannel_url && (
            <div className={css.socialItem}>
              <FaWhatsapp className={css.whatsappIcon} />
              {user?.attributes.profile.publicData.user_whatsapp_followers && (
                <span>
                  {formatFollowers(user?.attributes.profile.publicData.user_whatsapp_followers)}
                </span>
              )}
              {user?.attributes?.profile?.publicData.whatsapp_badge?.split('_')?.includes('yes') && (
                <IconVerified className={css.greenVerified} />
              )}
            </div>
          )}
          {user && user?.attributes?.profile?.publicData?.user_linkedin_url && (
            <div className={css.socialItem}>
              <FaLinkedin className={css.linkedinIcon} />
              {user?.attributes.profile.publicData.user_linkedin_followers && (
                <span>
                  {formatFollowers(user?.attributes.profile.publicData.user_linkedin_followers)}
                </span>
              )}
            </div>
          )}
        </div>
        {user?.attributes?.profile?.publicData?.categories?.length > 0 && (
          <div className={css.tags}>
            {user?.attributes?.profile?.publicData?.listingTypes?.map((tag, index) => (
              <span
                key={index}
                className={css.tag}
              >
                {tag === "collab_availability" ? "Creator" : "Marketer"}
              </span>
            ))}
            {user?.attributes?.profile?.publicData?.categories?.map((tag, index) => (
              <span
                key={index}
                className={css.tag}
              >
                {listingCategories?.find(c => c.id === tag)?.name || tag}
              </span>
            ))}
          </div>
        )}
      </div>

      <div className={css.buttons}>
        <NamedLink
          name="ProfilePage"
          params={{ id: user.id.uuid }}
          className={css.viewProfileButton}
        >
          View Profile
        </NamedLink>
        {!isOnWishlist && <Button
          type="button"
          rootClassName={classNames(css.collabButton, css.notFavourite)}
          disabled={updateCurrentUserInProgress}
          onClick={() => {
            setOpenWishlistSelector(user.id.uuid);
          }}
        >
          {'Add to favourite'}
        </Button>}
        {isOnWishlist && <Button
          type="button"
          rootClassName={css.collabButton}
          disabled={updateCurrentUserInProgress}
          onClick={() => {
            const updatedWishlists = currentUser?.attributes?.profile?.publicData?.wishlists?.map(wishlist => {
              if (wishlist.users.includes(user.id.uuid)) {
                return { ...wishlist, users: wishlist.users.filter(id => id !== user.id.uuid) };
              }
              return wishlist;
            });
            onUpdateCurrentUser({ publicData: { wishlists: updatedWishlists } });
          }}
        >
          {'Remove from favourite'}
        </Button>}
      </div>
    </div>
  );
};

/**
 * SearchResultsPanel component
 *
 * @component
 * @param {Object} props
 * @param {string} [props.className] - Custom class that extends the default class for the root element
 * @param {string} [props.rootClassName] - Custom class that extends the default class for the root element
 * @param {Array<propTypes.listing>} props.listings - The listings
 * @param {propTypes.pagination} props.pagination - The pagination
 * @param {Object} props.search - The search
 * @param {Function} props.setActiveListing - The function to handle the active listing
 * @param {boolean} [props.isMapVariant] - Whether the map variant is enabled
 * @returns {JSX.Element}
 */
const SearchResultsPanel = props => {

  const [openWishlistSelector, setOpenWishlistSelector] = React.useState(null);
  const [showNewWishlistInput, setShowNewWishlistInput] = useState(false);
  const [newWishlistName, setNewWishlistName] = useState("");

  const {
    className,
    rootClassName,
    listings = [],
    pagination,
    search,
    setActiveListing,
    isMapVariant = true,
    users,
    listingCategories,
    listingTypes,
    userFieldsConfig,
    onUpdateCurrentUser,
    updateCurrentUserInProgress,
    currentUser,
    onManageDisableScrolling
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  return (
    <div className={classes}>
      <div className={isMapVariant ? css.listingCardsMapVariant : css.listingCards}>
        {listings?.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes(isMapVariant)}
            setActiveListing={setActiveListing}
          />
        ))}
        {users && users.length > 0 && users.map(u => {
          const creatorNicheLabels = u?.attributes?.profile?.publicData?.creator_niche?.map(niche => {
            return userFieldsConfig?.find(c => c.key === 'creator_niche')?.enumOptions?.find(c => c.option === niche)?.label || null;
          }).filter(label => label !== null);
          const displayedCreatorNicheLabels = creatorNicheLabels?.slice(0, 3);
const creatorNicheText = creatorNicheLabels?.length > 3 ? `${displayedCreatorNicheLabels.join(', ')} and more` : displayedCreatorNicheLabels?.join(', ') || null;
          return (
            (
              <UserCard
                className={css.userCard}
                key={u.id.uuid}
                user={u}
                renderSizes={cardRenderSizes(isMapVariant)}
                listingCategories={listingCategories}
                listingTypes={listingTypes}
                onUpdateCurrentUser={onUpdateCurrentUser}
                updateCurrentUserInProgress={updateCurrentUserInProgress}
                currentUser={currentUser}
                creatorNiche={creatorNicheText ? creatorNicheText : null}
                setOpenWishlistSelector={setOpenWishlistSelector}
              />
            )
          )
        })}
        {users && users.length > 0 && users.map(u => {
          const creatorNicheLabels = u?.attributes?.profile?.publicData?.creator_niche?.map(niche => {
            return userFieldsConfig?.find(c => c.key === 'creator_niche')?.enumOptions?.find(c => c.option === niche)?.label || null;
          }).filter(label => label !== null);

          const displayedCreatorNicheLabels = creatorNicheLabels?.slice(0, 3);
          const creatorNicheText = creatorNicheLabels?.length > 3 ? `${displayedCreatorNicheLabels.join(', ')} and more` : displayedCreatorNicheLabels?.join(', ') || null;
          return (
            <ProfileSection
              currentUser={currentUser}
              user={u}
              listingCategories={listingCategories}
              listingTypes={listingTypes}
              creatorNiche={creatorNicheText ? creatorNicheText : null}
              onUpdateCurrentUser={onUpdateCurrentUser}
              updateCurrentUserInProgress={updateCurrentUserInProgress}
              setOpenWishlistSelector={setOpenWishlistSelector}
            />
          )
        })}
        {props.children}
      </div>
      {paginationLinks}
      <Modal
        id="WishlistSelector"
        isOpen={openWishlistSelector !== null}
        onClose={() => setOpenWishlistSelector(null)}
        onManageDisableScrolling={onManageDisableScrolling}
        className={css.modal}
        scrollLayerClassName={css.scrollLayer}
        containerClassName={css.modalContainer}
        closeButtonMessage=" "
        closeClassname={css.closeButton}
      >
        {/* 
          If user doesn't have any wishlist, show a message to create one adding the name and a button to create it
          If user has wishlists, show a list of them with a button to add the user to the selected wishlist or a button to create a new one

          Structure should be like this:
          wishlists: [
            {
              name: 'Wishlist 1',
              users: ['userid1', 'userid2', 'userid3']}
          ]
        */}
        <div className={css.modalContent}>
          {!currentUser?.attributes.profile.publicData.wishlists || currentUser?.attributes.profile.publicData.wishlists.length === 0 ? (
            <div>
              <h2>Create a new Favourite</h2>
              <input
                id="new-wishlist"
                name="new-wishlist"
                placeholder="Campaign name"
                type="text"
                value={newWishlistName}
                onChange={(e) => setNewWishlistName(e.target.value)}
              />
              <div className={css.modalButtons}>
                <Button
                  onClick={() => setOpenWishlistSelector(null)}
                  className={css.clearButton}
                >
                  Clear
                </Button>
                <Button
                  onClick={() => {
                    const newWishlist = {
                      name: newWishlistName,
                      users: [openWishlistSelector]
                    };

                    if (currentUser?.attributes.profile.publicData.wishlists) {
                      onUpdateCurrentUser({ publicData: { wishlists: [...currentUser?.attributes.profile.publicData.wishlists, newWishlist] } });
                    } else {
                      onUpdateCurrentUser({ publicData: { wishlists: [newWishlist] } });
                    }

                    setNewWishlistName(""); // Clear input after creation
                    setShowNewWishlistInput(false);
                    setOpenWishlistSelector(null);
                  }}
                >
                  Create
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <h2>Add to Favourite</h2>
              <div className="select-container">
                <select
                  onChange={(e) => setShowNewWishlistInput(e.target.value === "new")}
                >
                  {currentUser?.attributes?.profile?.publicData?.wishlists.map(wishlist => (
                    <option key={wishlist.id} value={wishlist.id}>
                      {wishlist.name}
                    </option>
                  ))}
                  <option value="new">Create new campaing</option>
                </select>
              </div>

              {/* Show input field when "Create new wishlist" is selected */}
              {showNewWishlistInput && (
                <input
                  id="new-wishlist"
                  name="new-wishlist"
                  placeholder="Campaign name"
                  type="text"
                  value={newWishlistName}
                  onChange={(e) => setNewWishlistName(e.target.value)}
                />
              )}
              <div className={css.modalButtons}>
                <Button
                  onClick={() => {
                    setOpenWishlistSelector(null);
                    setShowNewWishlistInput(false);
                    setNewWishlistName("");
                  }}
                  className={css.clearButton}
                >
                  Clear
                </Button>
                <Button
                  onClick={() => {
                    const selectedWishlist = document.querySelector('.select-container select').value;

                    if (selectedWishlist === "new") {
                      const newWishlist = {
                        name: newWishlistName,
                        users: [openWishlistSelector]
                      };

                      onUpdateCurrentUser({
                        publicData: {
                          wishlists: [...currentUser?.attributes.profile.publicData.wishlists, newWishlist]
                        }
                      });

                      setNewWishlistName("");
                      setShowNewWishlistInput(false);
                      setOpenWishlistSelector(null);
                    } else {
                      const updatedWishlists = currentUser?.attributes?.profile?.publicData?.wishlists?.map(wishlist => {
                        if (wishlist.name === selectedWishlist) {
                          return { ...wishlist, users: [...wishlist.users, openWishlistSelector] };
                        }
                        return wishlist;
                      });

                      onUpdateCurrentUser({ publicData: { wishlists: updatedWishlists } });
                      setOpenWishlistSelector(null);
                    }
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default SearchResultsPanel;
